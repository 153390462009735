import React, { useEffect, useState, useRef } from 'react';
import './Users.css';
import axios from '../Axios';
import { FaTrash, FaCheck, FaRegCircle, FaSearch } from 'react-icons/fa';
import profile from '../images/profile.jpeg';
import { toast } from 'react-hot-toast';
import Loadmore from './Pagination';
import { useUrl } from '../UrlContext';
import axios2 from 'axios';

const Tab = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div>
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => onTabChange(tab)}
          style={{
            backgroundColor: activeTab === tab ? 'black' : 'white',
            color: activeTab === tab ? '#e55855' : 'black',
            marginBottom: '10px',
            marginLeft: '10px',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '16px',
            cursor: 'pointer',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
          }}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

const User = () => {
  const [statePlayers, setStatePlayers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [activeTab, setActiveTab] = useState('State Players');
  const [isLoading, setIsLoading] = useState(false);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const [intialLoading, setIntialLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const lastTableRowRef = useRef(null);

  const { googleAccessToken } = useUrl();

  const getCurrentData = () => {
    if (activeTab === 'State Players') return statePlayers;
    if (activeTab === 'Players') return players;
    if (activeTab === 'Search Players') return searchUsers; 
    return [];
  };


  const setCurrentData = (data) => {
    if (activeTab === 'State Players') setStatePlayers(data);
    if (activeTab === 'Players') setPlayers(data);
    if (activeTab === 'Search Players') setSearchUsers(data);
  };

  async function addContact(accessToken, contactDetails, USER_ID) {
    const apiUrl = 'https://people.googleapis.com/v1/people:createContact';
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    try {
      const response = await axios2.post(apiUrl, contactDetails, { headers });
      axios.get(`/setContactFlagTrue/${USER_ID}`)
        .then(() => {})
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      console.log('Contact created:', response.data);
      toast.success(`Contact created: ${response.data.names[0].givenName}`);
    } catch (error) {
      console.error('Failed to create contact:', error);
      toast.error(`Failed to create contact. ${error.message}`);
    }
  }

  const toggleUserSelection = (user) => {
    if (selectedUsers.includes(user.USERID)) {
      setSelectedUsers(selectedUsers.filter(id => id !== user.USERID));
    } else {
      setSelectedUsers([...selectedUsers, user.USERID]);
    }
  };

  const isSelected = (userId) => selectedUsers.includes(userId);

  const rowStyle = (userId) => ({
    backgroundColor: isSelected(userId) ? '#f0f0f0' : 'white',
    cursor: 'pointer',
  });

  const handleSearch = () => {
    if (!searchQuery.trim()) {
      toast.error('Please enter a search term.');
      return;
    }
    setCurrentData([]);
    setAllDataFetched(false);
    loadMoreData(1, searchQuery);
  };

  const saveSelectedUsersToContacts = async () => {
    const selectedUserDetails = getCurrentData().filter(user => selectedUsers.includes(user.USERID));
    for (const user of selectedUserDetails) {
      try {
        await addContact(googleAccessToken, {
          names: [{
              givenName: user.NAME + " " + user?.CITY
          }],
          phoneNumbers: [{ value: user.PHONE }],
        }, user.USERID);

      } catch (error) {
        console.error(`Failed to save ${user.NAME}:`, error);
      }
    }
    setSelectedUsers([]);
    window.location.reload();
  };

  useEffect(() => {
    if (!allDataFetched && intialLoading) {
      loadMoreData(pageNo);
    }
    // eslint-disable-next-line
  }, [pageNo, intialLoading, allDataFetched]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab !== 'Search Players') { 
        setPageNo(1);
        setAllDataFetched(false);
        setCurrentData([]);  
    } else {
        setIntialLoading(true);
    }
};


  const loadMoreData = (page, search = '') => {
    let isStatePlayer = activeTab === 'State Players';
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(`/getAllUsers?pageNo=${page}&isStatePlayer=${isStatePlayer}&playerType=PLAYER&search=${search || searchQuery}`)
        .then((response) => {
          const newData = response.data.USERS;
          if (newData.length === 0) {
            setAllDataFetched(true);
          } else {
            setCurrentData((prevData) => [...prevData, ...newData]);
            setPageNo(page + 1);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }, 3000);
  };

  useEffect(() => {
    setIntialLoading(true);
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(timestamp).toLocaleDateString('en-US', options);
  };

  const formatTime = (timestamp) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(timestamp).toLocaleTimeString('en-US', options);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str;
    const words = str.trim().split(/\s+/);
    const capitalizedWords = words.map((word) => {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const capitalizedString = capitalizedWords.join(' ');
    return capitalizedString;
  }

  const handleDeleteUser = (userId) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this user?');
    if (shouldDelete) {
      axios.post('/removeUser', { USERID: userId })
        .then((response) => {
          toast.success('User deleted successfully:', response.data);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
        });
    }
  };

  const cellStyle = {
    borderBottom: '1px solid #ddd',
    padding: '8px',
  };

  const nowrapCellStyle = {
    ...cellStyle,
    whiteSpace: 'nowrap',
  };

  return (
    <div>
      <div>
        <Tab
          tabs={['State Players', 'Players', 'Search Players']}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />
      </div>
      {activeTab === 'Search Players' && (
        <div style={{ margin: '20px 10px' }}>
          <input
            type="text"
            placeholder="Search by ID, Name or Phone"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              padding: '10px',
              width: '30%',
              border: '1px solid #ddd',
              borderRadius: '5px',
              marginRight: '10px',
              color: 'black'
            }}
          />
          <button
            onClick={handleSearch}
            style={{
              padding: '12px 18px',
              height: '30%',
              backgroundColor: 'black',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            <FaSearch />
          </button>
        </div>
      )}
      <div>
        <div>
          <table className='fixed-table' style={{ color: 'black' }}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                {activeTab === 'State Players' ? <th>First Name</th> : <th>Phone</th>}
                <th>First Name</th>
                <th>Last Name</th>
                {activeTab === 'State Players' ? <th>Phone</th> : <th>Country Code</th>}
                <th>Gender</th>
                <th>DOB</th>
                <th>City</th>
                <th>Sign Up Time</th>
              </tr>
            </thead>
            <tbody>
              {getCurrentData().map((user, index) => (
                <tr key={index} ref={index === getCurrentData().length - 1 ? lastTableRowRef : null} style={rowStyle(user.USERID)} onClick={() => toggleUserSelection(user)}>
                  <td>
                    {user.CONTACT_FLAG ? <FaCheck /> : <FaRegCircle />}
                  </td>
                  <td>
                    <div className="profile-pic">
                      <img src={user.PROFILE_PIC_URL || profile} alt="Profile" />
                    </div>
                  </td>
                  <td>{user.USERID}</td>
                  <td>{user.NAME}</td>
                  <td>{user.LAST_NAME}</td>
                  {activeTab === 'State Players' ? <td>{user.PHONE}</td> : <td>{user.COUNTRY_CODE}</td>}
                  <td>{user.GENDER}</td>
                  <td>{formatDate(user.DOB) || ""}</td>
                  <td>{capitalizeFirstLetter(user.CITY)}</td>
                  <td style={nowrapCellStyle}>
                    {user.LOGIN_TIMESTAMP ? (
                      <div>
                        {formatDate(user.LOGIN_TIMESTAMP)}<br />
                        {formatTime(user.LOGIN_TIMESTAMP)}
                      </div>
                    ) : (
                      user.PWD && user.PWD !== "" ? "Signed Up" : ""
                    )}
                  </td>
                  <td onClick={(e) => { e.stopPropagation(); handleDeleteUser(user.USERID); }}>
                    <FaTrash />
                  </td>
                </tr>
              ))}
            </tbody>
            {isLoading && (<Loadmore />)}
            {allDataFetched && (
              <div style={{ color: 'red', textAlign: 'center', left: "40%" }}>All data has been loaded.</div>
            )}
          </table>

          {selectedUsers.length > 0 && (
            <div style={{ position: 'fixed', bottom: '10px', right: '40%', zIndex: 1000 }}>
              <button style={{ padding: '10px 20px', backgroundColor: '#e55855', color: 'white', borderRadius: '5px', cursor: 'pointer' }} onClick={saveSelectedUsersToContacts}>
                Save Selected to Contacts
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default User;
