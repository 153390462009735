import {React , useEffect , useState} from 'react';
import { useUrl } from '../UrlContext';
import axios from '../Axios';
import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';

function Dashboard() {
  const { selectedOption, url, updateUrl, setGoogleAccessToken } = useUrl();
  const [service,setService] = useState('');
  const [payment,setPayment] = useState('');

  const handleOptionChange = (event) => {
    const newSelectedOption = event.target.value;
    const newUrl =
      newSelectedOption === 'url1'
        ? 'https://ardentssports.azurewebsites.net'
        : 'https://ardentaws.ardentsport.com';
    updateUrl(newSelectedOption, newUrl);
    axios.defaults.baseURL = newUrl; 
    window.location.reload();
  };

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      console.log('Access Token:', tokenResponse.access_token);
      setGoogleAccessToken(tokenResponse.access_token); 
      toast.success('Logged in successfully and token saved.');
    },
    onError: error => {
      console.error('Login Failed:', error);
      toast.error('Login failed.');
    },
    scope: 'https://www.googleapis.com/auth/contacts',
  });

  const handleServiceChange = (event) => {
    const selectedService = event.target.value;
    const confirmed = window.confirm(`Are you sure you want to switch to ${selectedService}?`);
    if(confirmed){
      setService(selectedService);
      const otpServiceData = {
        messageCentral: selectedService === 'Message Central',
        firebase: selectedService === 'Firebase'
      };
      axios.post('/setOtpService', otpServiceData)
        .then((response) => {
          toast.success('Successfully set chosen otp service');
        })
        .catch((error) => {
          toast.error('Error updating OTP service');
          console.error('Error updating OTP service:', error);
        });
    }
  };

  const handlePaymentChange = (event) => {
    const selectedService = event.target.value;
    const confirmed = window.confirm(`Are you sure you want to switch to ${selectedService}?`);
    if(confirmed){
      setPayment(selectedService);
      const paymentServiceData = {
        phonepay: selectedService === 'Phonepe',
        razorpay: selectedService === 'Razorpay'
      };
      axios.post('/setPaymentService', paymentServiceData)
        .then((response) => {
          toast.success('Successfully set chosen payment service');
        })
        .catch((error) => {
          toast.error('Error updating Payment service');
          console.error('Error updating Payment service:', error);
        });
    }
  };

  useEffect(() => {
    axios.get('/getOtpService')
      .then((response) => {
        if(response.data.otp.firebase)
         setService('Firebase');
        else
         setService('Message Central');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      axios.get('/getsettings')
      .then((response) => {
        if(response.data.payment.razorpay)
         setPayment('Razorpay');
        else
         setPayment('Phonepe');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
    <div style={{ color: 'black' }}>
      <div>
        <label>
          Select Server:
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            style={{
              margin: '10px',
              width: '10rem',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              outline: 'none',
            }}
          >
            <option value="url1">Live Server</option>
            <option value="url2">Test Server</option>
          </select>
        </label>
        
      </div>
      Current URL : <span style={{ fontWeight: 'bolder' }}>{url}</span>
    </div>
    <div style={{ color: 'black', marginTop: '5%' }}>
      <div>
        <label>
          Select OTP Service:
          <select
            value={service}
            onChange={handleServiceChange}
            style={{
              margin: '10px',
              width: '10rem',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              outline: 'none',
            }}
          >
            <option value="Firebase">Firebase</option>
            <option value="Message Central">Message Central</option>
          </select>
        </label>
      </div>
      Current OTP Service : <span style={{ fontWeight: 'bolder' }}>{service}</span>
    </div>
    <div style={{ color: 'black', marginTop: '5%' }}>
      <div>
        <label>
          Select Payment Service:
          <select
            value={payment}
            onChange={handlePaymentChange}
            style={{
              margin: '10px',
              width: '10rem',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              outline: 'none',
            }}
          >
            <option value="Phonepe">Phonepe</option>
            <option value="Razorpay">Razorpay</option>
          </select>
        </label>
      </div>
      Current Payment Service : <span style={{ fontWeight: 'bolder' }}>{payment}</span>
    </div>
    <div style={{ marginTop: '5%' }}>
      <button onClick={() => login()}>Login with Google</button>
    </div>
    </>
  );
}

export default Dashboard;
